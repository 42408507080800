<template>
  <div class="container">
    <div class="head_container">
      <div class="head_main">
        <el-image
          class="head_img"
          :src="require('@as/serve/strict/banner.png')"
        ></el-image>
        <div class="head_info">
          <div class="info_title">
            2020众合真金题分类历年真题详解新法律职业资格考试
          </div>
          <div class="info_subtitle">
            2020众合真金题分类历年真题详解新法律职业资格考试2020众合真金题分类历年真题详解新法律职业资格考试
          </div>
          <div class="info_size">
            <div class="size_name">商品规格</div>
            <div class="size_type">
              <div class="size_label">商品类型</div>
              <div
                class="size_tag"
                :class="{ active: activeTag == 1 }"
                @click="activeTag = 1"
              >
                电子版
              </div>
              <div
                class="size_tag"
                :class="{ active: activeTag == 2 }"
                @click="activeTag = 2"
              >
                纸质版
              </div>
            </div>
            <div class="size_type">
              <div class="size_label">数量</div>
              <el-input-number
                v-model="num"
                @change="handleChange"
                size="mini"
                :min="1"
                :max="10"
                label="描述文字"
              ></el-input-number>
            </div>
          </div>
          <div class="info_price">
            <div class="price_label">售价</div>
            <div class="price_num">
              <div class="symbol">￥</div>
              <div class="num">38.80</div>
            </div>
          </div>
          <div class="info_btn_container">
            <div class="info_btn" @click="toBuy()">立即购买</div>
            <div class="info_btn" @click="toBuy()">加入购物车</div>
            <div class="info_collect">
              <el-image
                class="collect_icon"
                :src="require('@as/serve/strict/collect.png')"
              ></el-image>
              <div>收藏</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main_left">
        <div class="section">
          <el-menu
            :default-active="active"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">商品详情</el-menu-item>
            <el-menu-item index="2">评价</el-menu-item>
            <el-menu-item index="3">常见问题</el-menu-item>
          </el-menu>
          <template v-if="active == 1">
            <div class="section_content">简介内容</div>
          </template>
          <template v-else-if="active == 2">
            <div class="section_content">
              <div class="list">
                <div class="list_section" v-for="item in 3" v-bind:key="item">
                  <div class="left">
                    <el-image
                      class="list_icon"
                      :src="require('@as/serve/knowledge/icon_5.png')"
                    ></el-image>
                    <div class="name">大人物</div>
                  </div>
                  <div class="info">
                    <div class="star">
                      <el-image
                        class="star_icon"
                        v-for="idx in 5"
                        v-bind:key="idx"
                        :src="
                          item >= idx
                            ? require('@as/serve/strict/star_active.png')
                            : require('@as/serve/strict/star.png')
                        "
                      ></el-image>
                    </div>
                    <div class="detail">感觉每年的设计趋势都有那几个大条～</div>
                    <div class="time">2020-11-13</div>
                  </div>
                </div>
                <el-pagination
                  small
                  layout="prev, pager, next"
                  :background="true"
                  :total="50"
                >
                </el-pagination>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="section_content">常见问题内容</div>
          </template>
        </div>
      </div>
      <div class="main_right">
        <div class="section">
          <div class="head">
            <div class="title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/strict/icon_5.png')"
              ></el-image>
              <div>相关推荐</div>
            </div>
            <div class="title_more"></div>
          </div>
          <div class="row" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="name">保险资金运用合规管理及争议解决热点</div>
              <div class="price">￥32.80</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="head">
            <div class="title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/knowledge/icon_7.png')"
              ></el-image>
              <div>相关咨询</div>
            </div>
            <div class="title_more">MORE</div>
          </div>
          <div class="row last" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="title">媒体合作咨询</div>
              <div class="subtitle">姓名：陈俊</div>
              <div class="subtitle">电话：2485</div>
              <div class="subtitle">邮箱：2485@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 1,
      active: '1',
      activeTag: 1,
    }
  },
  methods: {
    handleChange(value) {
      console.log(value)
    },
    handleSelect(value) {
      this.active = value
      console.log(value)
    },
    toBuy() {
      this.$router.push('buy')
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  background: #f5f6fa;
}
.head_container {
  padding: 20px 0;
  background: #fff;
  .head_main {
    margin: 0 auto 40px;
    display: flex;
    align-items: center;
    width: 1200px;
    .head_img {
      width: 480px;
      height: 480px;
    }
    .head_info {
      margin-left: 40px;
      .info_title {
        font-size: 20px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .info_subtitle {
        margin-top: 20px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
      }
      .info_size {
        position: relative;
        margin-top: 55px;
        padding: 30px 20px;
        width: 680px;
        border: 1px solid #dedede;
        box-sizing: border-box;
        .size_name {
          position: absolute;
          left: 50px;
          bottom: 97%;
          padding: 0 5px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
          background: #fff;
        }
        .size_type {
          display: flex;
          align-items: center;
          &:last-child {
            margin-top: 26px;
          }
          .size_label {
            margin-right: 20px;
            flex-shrink: 0;
            min-width: 48px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
          .size_tag {
            margin-right: 7px;
            padding: 6px 16px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 300;
            color: #666666;
            border: 1px solid #cccccc;
            background: #fff;
            cursor: pointer;
            &.active {
              color: #ffffff;
              background: #1c9ab1;
              border-color: transparent;
            }
          }
        }
      }
      .info_price {
        margin-top: 25px;
        padding: 30px 20px;
        display: flex;
        align-items: center;
        background: #f5f6fa;
        .price_label {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 300;
          color: #333333;
        }
        .price_num {
          margin-left: 32px;
          display: flex;
          .symbol {
            padding-top: 4px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 300;
            color: #666666;
          }
          .num {
            font-size: 24px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ea534c;
          }
        }
      }
      .info_btn_container {
        margin-top: 50px;
        display: flex;
        .info_btn {
          width: 200px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          color: #ffffff;
          border: 1px solid #1c9ab1;
          background: #1c9ab1;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          &:first-child {
            margin-right: 10px;
            background: #f5f6fa;
            color: #1c9ab1;
          }
        }
        .info_collect {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;

          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 300;
          color: #666666;
          background: #f5f6fa;
          border: 1px solid #1c9ab1;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
          .collect_icon {
            margin-bottom: 5px;
            width: 18px;
            height: 17px;
          }
        }
      }
    }
  }
}
.main {
  margin: 20px auto 0;
  display: flex;
  width: 1200px;
  .main_left {
    flex: 1;
    & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 2px solid #4c9bbb;
    }
    & /deep/ .el-menu--horizontal > .el-menu-item {
      color: #595757;
    }
    & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
      color: #4c9bbb;
    }

    .section {
      margin-bottom: 20px;
      .section_content {
        padding: 30px;
        background: #fff;
        .row {
          padding: 14px 20px;
          display: flex;
          align-items: center;
          background: #f5f6fa;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          .row_icon {
            width: 28px;
            height: 22px;
          }
          .row_title {
            margin: 0 13px 0 18px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
          }
          .row_time {
            font-size: 14px;
            color: #999;
          }
          .row_btn {
            font-size: 14px;
            color: #579ed5;
          }
        }

        .list {
          .list_section {
            padding: 20px 0;
            display: flex;
            border-bottom: 1px solid #eee;
            &:first-child {
              padding-top: 0;
            }
            .left {
              margin-right: 20px;
              text-align: center;
              .list_icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
              .name {
                margin-top: 6px;
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #333333;
              }
            }
            .info {
              flex: 1;
              .star_icon {
                margin-right: 6px;
                width: 14px;
                height: 14px;
              }
              .detail {
                margin-top: 13px;
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #333333;
              }
              .time {
                margin-top: 20px;
                font-size: 14px;
                font-family: PingFangSC;
                font-weight: 400;
                color: #999999;
              }
            }
          }
          & /deep/ .el-pagination {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
          }
          & /deep/ .el-pagination.is-background .btn-prev:disabled {
            color: #999;
            background: transparent;
          }
          & /deep/ .el-pagination.is-background .btn-prev {
            background: transparent;
          }
          & /deep/ .el-pagination.is-background .btn-next:disabled {
            color: #606266;
            background: transparent;
          }
          & /deep/ .el-pagination.is-background .btn-next {
            background: transparent;
          }
          & /deep/ .el-pagination.is-background .el-pager li {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
            background: transparent;
          }
          &
            /deep/
            .el-pagination.is-background
            .el-pager
            li:not(.disabled).active {
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            background: #1c9ab1;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .main_right {
    margin-left: 20px;
    width: 330px;
    .section {
      padding: 23px 25px 0;
      margin-bottom: 20px;
      background: #fff;
      .head {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .title {
          padding-bottom: 6px;
          display: flex;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border-bottom: 2px solid #4a97ad;
          .title_icon {
            margin-right: 8px;
            width: 22px;
            height: 20px;
          }
        }
        .title_more {
          padding-bottom: 6px;
          flex: 1;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          text-align: right;
          border-bottom: 1px solid #dedede;
        }
      }
      .row {
        margin: 0 -25px;
        padding: 17px 31px;
        display: flex;
        cursor: pointer;
        &:hover {
          box-shadow: 2px 2px 6px 0 rgba(000, 000, 000, 0.1);
        }
        &:not(:last-child) {
          border-bottom: 1px solid #dedede;
        }
        &:last-child {
          padding-bottom: 40px;
        }
        &.last {
          align-items: flex-start;
          .img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .info {
            margin-top: 10px;
          }
        }
        .img {
          margin-right: 15px;
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          border-radius: 10px;
        }
        .info {
          .name {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #666666;
          }
          .price {
            margin-top: 14px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #eb6e69;
          }
          .title {
            margin: 4px 0 12px;
            font-size: 15px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #595757;
          }
          .subtitle {
            margin-top: 10px;
            font-size: 13px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a7a8a8;
          }
        }
      }
    }
  }
}
</style>